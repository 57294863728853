/**
 * GeoScape API Helper
 * 
 * Handles the formatting of the URL and actioning the fetch function
 * 
 * @param   {String} endpoint       The GeoScape API endoint you need to call.
 * @param   {String} method         (Optional) The method for the call. Valid options are GET, POST, PUT, DELETE. Defaults to GET.
 * @param   {String|Object} body    (Optional) The body of the call if required. Will access either a stringified object or an object. If an object passed, it will be stringified before entry.
 * 
 * @return  {Object}                {response, status}
 *
    import { gsApi } from '../helpers/geoscape'

    gsApi('endpoint', 'POST', bodyObject).then(({response, status}) => {
        console.log(response, status);
    }).catch(error => console.error(error));
 */
    async function gsApi(endpoint, method, body) {
        const options = {
            method: method ? method : 'GET',
            credentials: 'same-origin',
            mode: 'same-origin'
        };
        
        if (body) {
            let bodyString = body;
            if (typeof body === 'object') {
            bodyString = JSON.stringify(body);
            }
        
            options.body = bodyString;
        }
        
        const parseJson = async response => {
            const text = await response.text();
            try {
            const json = JSON.parse(text);
            return json;
            } catch (err) {
            return text;
            }
        };
    
        const encodedEndpoint = Buffer.from(endpoint).toString('base64');
        
        return await fetch(
            `${process.env.LAMBDA_PATH}geoscape?endpoint=${encodedEndpoint}`,
            options
        ).then(async res => ({ response: await parseJson(res), status: res.status }));
    }
    
    /**
     * Suggest address
     * 
     * Send typed address to return results
     * 
     * @param {*} address 
     * 
        import _ from 'lodash';
        import { predictAddress } from '../helpers/geoscape'
    
        const triggerSearch = _.debounce(async (address) => {
            const suggestions = await predictAddress(address);
            if (suggestions) {
                // Do what you need to with results
            }
        }, 200, {trailing: true});
    
        const searchAddress = (address) => {
            triggerSearch(address);
        }
        */
    async function predictAddress(address) {
        if (address.length >= 3) {
            const suggestions = await gsApi(`predictive/address?query=${encodeURI(address)}`);
            // console.log("Predict", suggestions);
            if (suggestions.status === 200) {
                if ('suggest' in suggestions.response) {
                    return {type: 'results', results: suggestions.response.suggest};
                } else if ('message' in suggestions.response) {
                    return {type: 'message', message: suggestions.response.message};
                } else {
                    return {type: 'error', error: suggestions.response};
                }
            } else {
                return {type: 'error', error: (typeof suggestions.response === 'object' && 'messages' in suggestions.response && suggestions.response.messages[0]) || suggestions.response};
            }
        } else {
            return {type: 'invalid', invalid: 'Requires more characters for search'};
        }
    }
    
    /**
     * Fetch address details
     * 
     * @param {*} addressId 
     *
        import { getAddressDetails } from '../helpers/geoscape'
    
        getAddressDetails(addressId).then(({ type, results, message, error }) => {
            console.log(type, results, message, error);
        }).catch(error => console.error(error));
        */
    async function getAddressDetails(addressId) {
        const address = await gsApi(`predictive/address/${addressId}`);
        // console.log("Address", address);
        if (address.status === 200) {
            if ('address' in address.response) {
                return {type: 'address', address: address.response.address};
            } else {
                return {type: 'error', error: address.response};
            }
        } else {
            return {type: 'error', error: address.response};
        }
    }
        
    /**
     * Fetch address type
     * 
     * @param {*} addressId
     *
        import { fetchAddressType } from '../helpers/geoscape'
    
        fetchAddressType(addressId).then(({ type, results, message, error }) => {
            console.log(type, results, message, error);
        }).catch(error => console.error(error));
        */
    async function fetchAddressType(addressId) {
        const building = await gsApi(`buildings?addressId=${addressId}&include=zonings`);
        // console.log("Building Data", building);
        return building;
    }
        
    export { 
        gsApi,
        predictAddress,
        getAddressDetails,
        fetchAddressType
    };
import React, { useContext, useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import CartContext from '../../../context/CartProvider';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';
import Button from '../../atoms/Button/Button';
import Dialog from '../../atoms/Dialog/Dialog';
import * as pcStyles from './CartSummary.module.css';
import * as mpsStyles from './CartSummary.mps.module.scss';
import { storeStyle } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const CouponForm = () => {
  const {
    state: {
      coupons,
      cart: { currency }
    },
    addCoupons,
    removeCoupons,
    initCheckout
  } = useContext(CartContext);

  const [initLoad, setInitLoad] = useState(true);
  const [applying, setApply] = useState(false);
  const [couponCode, setCouponCode] = useState('');
  const [assignedCoupon, setAssignedCoupon] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [dialogMessage, setDialogMessage] = useState(false);

  const handleCancel = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return false;
    }
    setDialogMessage(false);
  };

  const onClickToggle = () => {
    setShowForm(!showForm);
  };

  const onChangeValue = e => {
    const { value } = e.target;
    setCouponCode(value);
  };

  const onSubmitForm = () => {
    setApply(true);
    addCoupons(couponCode)
      .then(response => {
        setAssignedCoupon(response);
        initCheckout();
        setApply(false);
      })
      .catch(response => {
        // console.log("Error: ", response);
        setApply(false);
        setDialogMessage(response.title);
      });
  };

  const handleRemoveCoupon = code => () => {
    setApply(true);
    removeCoupons(code).then(response => {
      setAssignedCoupon([]);
      initCheckout();
      setApply(false);
    });
  };

  const clearDialog = () => {
    setDialogMessage(false);
  };

  useEffect(() => {
    if (initLoad) {
      setInitLoad(false);
      setAssignedCoupon(coupons);
    }
  }, [initLoad, coupons, assignedCoupon]);

  useEffect(() => {
    if (isEmpty(coupons)) {
      setAssignedCoupon([]);
    }
  }, [coupons]);

  return (
    <>
      {isEmpty(assignedCoupon) ? (
        <div className="flex-between">
          <span className={`${styles.bcCartSummaryItemLabel} ${styles.promoLabel}`}>
            Promo Code
          </span>
          <span
            data-button
            role="presentation"
            onClick={onClickToggle}
            className={styles.bcCartSummaryToggle}
          >
            {showForm ? 'Cancel' : 'Add Code'}
          </span>
        </div>
      ) : (
        assignedCoupon.map(coupon => (
          <div className="flex-between" key={coupon.code}>
            <div className={styles.bcCartSummaryItemLabel}>
              <div>{coupon.name || `Coupon (${coupon.code})`}</div>
              <span
                data-button
                role="presentation"
                onClick={handleRemoveCoupon(coupon.code)}
                className={styles.bcCartSummaryToggle}
              >
                {applying ? 'Removing...' : 'Remove'}
              </span>
            </div>
            <span className={`${styles.bcCartSummaryItemValue} ${styles.bcDiscount}`}>
              -
              <CurrencyFormatter
                currency={currency.code}
                amount={coupon.discounted_amount}
                ignoreTax={true}
              />
            </span>
          </div>
        ))
      )}
      {isEmpty(assignedCoupon) && showForm && (
        <div className={`pt-4 ${styles.addCouponsStyle}`}>
          <div className="formField mb-2">
            <input
              type="text"
              name="couponCode"
              placeholder="Enter your coupon code"
              value={couponCode || ''}
              onChange={onChangeValue}
            />
          </div>
          <Button
            type="button"
            level="primary"
            disabled={applying}
            onClick={onSubmitForm}
          >
            {applying ? 'Applying...' : 'Apply'}
          </Button>
        </div>
      )}
      <Dialog
        open={dialogMessage ? true : false}
        title="Applying coupon"
        size="sm"
        hideBtnCancel
        disableBackdropClick
        onClose={(event, reason) => handleCancel(event, reason)}
        onOk={() => clearDialog()}
      >
        {dialogMessage}
      </Dialog>
    </>
  );
};

export default CouponForm;

import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getUriFromURL } from '../../../../helpers/general';
import CurrencyFormatter from '../../../atoms/CurrencyFormatter/CurrencyFormatter';
import Icon from '../../../atoms/Icon/Icon';
import Link from '../../../atoms/Link/Link';
import Loader from '../../../atoms/Loader/Loader';
import AdjustItem from '../../../molecules/AdjustItem/AdjustItem';
import { useMediaQuery } from '@chakra-ui/react';
import * as pcStyles from '../Cart.module.scss';
import * as mpsStyles from '../Cart.mps.module.scss';
import { storeStyle } from '../../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const CartItem = ({
  cartType,
  currency,
  item,
  productInfo,
  itemType,
  updatingItem,
  removeItemFromCart = () => null,
  updateCartItemQuantity = () => null
}) => {
  // const [showAdjustMobile, setShowAdjustMobile] = useState(false);
  const [isPc] = useMediaQuery('(min-width: 800px)', {
    ssr: true,
    fallback: false // return false on the server, and re-evaluate on the client side
  });

  const [title, uri, sku] = useMemo(() => {
    // const getProductTitle = () => {
    //   // TODO: Check for BC variant and append to title
    //   return dissectTitle(item.name, '(')[0];
    // };

    const productTitle = item.name;
    const productUri = getUriFromURL(item.url);
    if (itemType === 'gift') {
      return [
        `${productTitle} - Gift Certificate for ${item.recipient.name}`,
        productUri,
        `Theme: ${productTitle}`
      ];
    }
    return [productTitle, productUri, item.sku];
  }, [itemType, item]);

  const isMedication = useMemo(() => {
    if (productInfo && productInfo[item.product_id]) {
      return productInfo[item.product_id].custom_fields.find(
        field => field.name === 'Product Type' && field.value === 'Medication'
      );
    }
    return false;
  }, [productInfo, item]);

  const autoShip = useMemo(() => {
    let val = {
      isAutoship: false,
      hasAutoship: false,
      less: 0
    };
    if (productInfo && productInfo[item.product_id]) {
      const findModifier = productInfo[item.product_id].modifiers.find(
        field => field.name === 'Ships Every'
      );

      if (findModifier) {
        val.hasAutoship = !!findModifier;
        val.less = findModifier.option_values?.[0]?.adjusters?.price?.adjuster_value * -1;
      }
    }

    const findAutoship = item.options?.find(field => field.name === 'Ships Every');

    val.isAutoship = !!findAutoship;

    return val;
  }, [productInfo, item]);

  const itemImage = useMemo(() => {
    if (cartType === 'full') {
      return (
        <div className={styles.bcCartImage}>
          {isMedication && (
            <div className="medication">
              {' '}
              <Icon symbol={'prescription'} />
            </div>
          )}
          <Link url={uri}>
            {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {itemType === 'custom' && (
              <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
            )}
            {itemType === 'gift' && (
              <img
                loading="lazy"
                src={`/theme/${item.theme.toLowerCase()}.webp `}
                alt={`${item.name}`}
              />
            )}
          </Link>
        </div>
      );
    }
    if (cartType === 'mini') {
      return (
        <div className={styles.bcMiniCartImage}>
          <Link url={uri}>
            {!itemType && <img loading="lazy" src={item.image_url} alt={`${item.name}`} />}
            {itemType === 'custom' && (
              <img loading="lazy" src="/img/default-bc-product.png" alt={`${item.name}`} />
            )}
          </Link>
        </div>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cartType, item, itemType, uri]);

  const formatDate = date => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()} ${dateObj.toLocaleString('default', {
      month: 'long'
    })}, ${dateObj.getFullYear()}`;
  };

  const calculatePercentageDecrease = (original, newPrice) => {
    const difference = original - newPrice;
    const percentageDecrease = (difference / original) * 100;
    return ` ${Math.round(percentageDecrease)}`; // Returns rounded percentage
  };

  const isMiniCart = cartType === 'mini';
  const isUpdating = updatingItem === item.id;

  return (
    <div
      className={clsx(
        styles.bcCartItem,
        isMiniCart && styles.bcMiniCartItem,
        isUpdating && styles.disableAction,
        !isMiniCart && styles.bcCartItemFull
      )}
      data-sku={sku}
    >
      <div
        className={clsx(
          !isMiniCart && styles.bcTitleImage,
          isMiniCart && styles.bcMiniCartItemRow,
          isMiniCart && isUpdating && styles.isWorking
        )}
      >
        {itemImage}
        <div className={styles.cartItemDetails}>
          <div className={styles.rowCartItemDetails}>
            <div className={isMiniCart ? styles.bcProductTitleMini : styles.bcProductTitleFull}>
              <Link url={uri}>
                {title}
                {item.options && (
                  <>
                    {item.options
                      ?.filter(option => option.name !== 'Ships Every')
                      .map((option, optionIndex) => (
                        <React.Fragment key={optionIndex}>
                          &nbsp;-&nbsp;{option.value}
                        </React.Fragment>
                      ))}
                  </>
                )}
              </Link>
              {!isMiniCart && !isPc ? (
                <span
                  data-button
                  role="presentation"
                  className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
                  onClick={() => removeItemFromCart(item.id)}
                >
                  <Icon symbol="bin" />
                </span>
              ) : (
                <>
                  {autoShip.isAutoship && !isNaN(autoShip.less) && autoShip.less > 0 && (
                    <div className={styles.autoShipDes}>
                      <p>You saved {autoShip.less}% on this order with</p>{' '}
                      <Icon symbol={'auto-ship'} />
                    </div>
                  )}
                  {autoShip.hasAutoship &&
                    !autoShip.isAutoship &&
                    !isNaN(autoShip.less) &&
                    autoShip.less > 0 && (
                      <div className={styles.autoShipDes}>
                        <p>You could save {autoShip.less}% with</p> <Icon symbol={'auto-ship'} />
                      </div>
                    )}
                </>
              )}
            </div>
            {!isMiniCart && !isPc && (
              <div className={styles.rowCartItemDetailsBottom}>
                <div>
                  {itemType !== 'gift' && !['INS', 'REPLY', 'SIGREQ'].includes(item.sku) && (
                    <AdjustItem
                      isCart={true}
                      item={item}
                      cartType={cartType}
                      updatingItem={updatingItem}
                      updateCartItemQuantity={updateCartItemQuantity}
                    />
                  )}
                </div>
                <div>
                  <CurrencyFormatter
                    currency={currency.code}
                    amount={item.extended_list_price || item.amount}
                    ignoreTax={true}
                  />
                </div>
              </div>
            )}

            {isMiniCart && (
              <div className={styles.bcMiniCartPrice}>
                <div>
                  <CurrencyFormatter
                    currency={currency.code}
                    amount={item.extended_list_price || item.amount}
                    ignoreTax={true}
                  />
                </div>
              </div>
            )}
          </div>
          {isMiniCart && (
            <div className={`${styles.rowCartItemDetails} ${styles.qtyContainer}`}>
              <div className={styles.bcProductDetail}>
                Qty: {item.quantity}
                {productInfo && productInfo[item.product_id]?.availability === 'preorder' && (
                  <span className={styles.preorder}>
                    {productInfo[item.product_id].preorder_message.replace(
                      '%%DATE%%',
                      formatDate(productInfo[item.product_id].preorder_release_date)
                    )}
                  </span>
                )}
              </div>
              {isUpdating ? (
                <span className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}>
                  <Loader size="small" />
                </span>
              ) : (
                <span
                  data-button
                  role="presentation"
                  className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
                  onClick={() => removeItemFromCart(item.id)}
                >
                  <Icon symbol="close" />
                </span>
              )}
            </div>
          )}
        </div>
      </div>
      {!isMiniCart && (
        <div className={styles.bcCartDetils}>
          <div className={clsx('flex-center')}>
            {itemType !== 'gift' && !['INS', 'REPLY', 'SIGREQ'].includes(item.sku) && (
              <AdjustItem
                isCart={true}
                item={item}
                cartType={cartType}
                updatingItem={updatingItem}
                updateCartItemQuantity={updateCartItemQuantity}
              />
            )}
          </div>
          <div className={clsx('flex-between', styles.bcCartPrice)}>
            <div>
              <div>
                <CurrencyFormatter
                  currency={currency.code}
                  amount={item.extended_list_price || item.amount}
                  ignoreTax={true}
                />
              </div>
              <div className={styles.oldPrice}>
                {(item.original_price * item.quantity)?.toFixed(2) !==
                  item.extended_list_price?.toFixed(2) &&
                  itemType !== 'gift' && (
                    <CurrencyFormatter
                      currency={currency.code}
                      amount={item.original_price * item.quantity}
                      ignoreTax={true}
                    />
                  )}
              </div>
              {!autoShip.isAutoship && (
                <div className={styles.discount}>
                  {(item.original_price * item.quantity)?.toFixed(2) !==
                    item.extended_list_price?.toFixed(2) &&
                    itemType !== 'gift' && (
                      <>
                        Saved
                        {calculatePercentageDecrease(
                          item.original_price * item.quantity,
                          item.extended_list_price
                        )}
                        %
                      </>
                    )}
                </div>
              )}
            </div>
          </div>
          <span
            data-button
            tabIndex={0} // eslint-disable-line jsx-a11y/no-noninteractive-tabindex
            role="presentation"
            className={clsx(styles.bcCartBtn, 'icon-wrap', 'ml-4')}
            onClick={() => removeItemFromCart(item.id)}
          >
            Remove <Icon symbol="bin" />
          </span>
        </div>
      )}
    </div>
  );
};

CartItem.propTypes = {
  removeItemFromCart: PropTypes.func,
  updateCartItemQuantity: PropTypes.func
};

export default CartItem;

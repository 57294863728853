export const stateTranslations = {
  ACT: 'Australian Capital Territory',
  NSW: 'New South Wales',
  NT: 'Northern Territory',
  QLD: 'Queensland',
  SA: 'South Australia',
  TAS: 'Tasmania',
  VIC: 'Victoria',
  WA: 'Western Australia'
};

export const requiredAddressFields = [
  'first_name',
  'last_name',
  'phone',
  'address1',
  'city',
  'country_code',
  'state_or_province',
  'postal_code'
];

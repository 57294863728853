import React, { useContext, useMemo } from 'react';
import Link from '../../atoms/Link/Link';
import clsx from 'clsx';
import CartContext from '../../../context/CartProvider';
import CartSummary from '../../molecules/CartSummary/CartSummary';
import CartHeader from './components/CartHeader';
import CustomItems from './components/CustomItems';
import StandardItems from './components/StandardItems';
import GiftCertificateItems from './components/GiftCertificateItems';
import * as pcStyles from './Cart.module.scss';
import * as mpsStyles from './Cart.mps.module.scss';
import { Box, Flex, Text } from '@chakra-ui/react';
import Icon from '../../atoms/Icon/Icon';
import LoadingOverlay from '../../atoms/LoadingOverlay/LoadingOverlay';
import { useGlobalStore } from '../../../stores/global';
import { storeStyle, isMadPawsStore } from '../../../common/util';
// TODO: Update buttons to atom component
// TODO: Incomplete component - needs to be finished. Please update the readme when complete.
const styles = storeStyle({ mpsStyles, pcStyles });

const Cart = ({ cartType, onClick }) => {
  const ctx = useContext(CartContext) || { state: { cart: {} } };
  const { frequency, setIsAutoShip } = useGlobalStore(state => state);

  const { state, removeItemFromCart, updateCartItemQuantity } = ctx;
  const { currency, lineItems, productInfo, numberItems } = state.cart;
  const { updatingItem, cartLoading } = state;
  //separate items with option name Autoship Frequency.

  const isMedication = useMemo(() => {
    if (lineItems && productInfo) {
      return lineItems?.physical_items?.some(item =>
        productInfo[item.product_id]?.custom_fields?.some(
          field => field.name === 'Product Type' && field.value === 'Medication'
        )
      );
    }
    return false;
  }, [lineItems, productInfo]);

  const physicalItems = useMemo(() => {
    const itemsWithFrequency = lineItems.physical_items.filter(item =>
      item.options.find(option => option.name === 'Ships Every')
    );

    setIsAutoShip(itemsWithFrequency.length > 0);

    const itemsWithoutFrequency = lineItems.physical_items.filter(
      item => !!!item.options.find(option => option.name === 'Ships Every')
    );
    return { itemsWithFrequency, itemsWithoutFrequency };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItems.physical_items]);
  if (cartLoading) {
    return (
      <div className="bc-cart__empty">
        <h2 className="bc-cart__title--empty">
          <em>Loading Cart</em>
        </h2>
      </div>
    );
  }

  if (!numberItems) {
    return (
      <div className="text-center">
        <p className="subtitle small text-center">Your cart is empty.</p>
        <Link url="/shop/" className={styles.bcCartLink}>
          Take a look around.
        </Link>
      </div>
    );
  }

  return (
    <>
      {cartType === 'full' && physicalItems.itemsWithFrequency.length > 0 && (
        <>
          <div className={styles.cycle}>
            <p className={styles.subTitle}>Autoship</p>
            <div className={styles.frequency}>
              <Icon symbol={'cycle'} />
              <span>
                Every{' '}
                {
                  physicalItems?.itemsWithFrequency?.[0]?.options?.find(
                    option => option.name === 'Ships Every'
                  )?.value
                }
              </span>
            </div>
          </div>
          <Box
            pos={'relative'}
            className={clsx(
              cartType === 'full' && styles.bcCartContainer,
              cartType === 'mini' && styles.bcMiniCartContainer
            )}
          >
            {frequency && <LoadingOverlay position="absolute" />}
            <div className="bc-cart-error">
              <p className="bc-cart-error__message"></p>
            </div>

            {cartType === 'full' && <CartHeader />}

            <div className={clsx(cartType === 'mini' && styles.bcMiniCartContent)}>
              <StandardItems
                currency={currency}
                updatingItem={updatingItem}
                updateCartItemQuantity={updateCartItemQuantity}
                removeItemFromCart={removeItemFromCart}
                items={physicalItems.itemsWithFrequency}
                productInfo={productInfo}
                cartType={cartType}
              />
            </div>
          </Box>
        </>
      )}
      {(physicalItems.itemsWithoutFrequency.length > 0 ||
        lineItems.digital_items.length > 0 ||
        lineItems.custom_items.length > 0 ||
        lineItems.gift_certificates.length > 0) && (
        <>
          {physicalItems.itemsWithFrequency.length > 0 && (
            <div className={styles.cycle}>
              <p className={styles.subTitle}>One-off</p>
            </div>
          )}
          <Box
            pos={'relative'}
            className={clsx(
              cartType === 'full' && styles.bcCartContainer,
              cartType === 'mini' && styles.bcMiniCartContainer
            )}
          >
            {frequency && <LoadingOverlay position="absolute" />}
            <div className="bc-cart-error">
              <p className="bc-cart-error__message"></p>
            </div>

            {cartType === 'full' && <CartHeader />}
            <div className={clsx(cartType === 'mini' && styles.bcMiniCartContent)}>
              <div
                className={`bc-cart-list ${
                  cartType === 'full' ? styles.cartListContainerFull : ''
                }`}
                data-cartitemlist
              >
                {cartType === 'full' ? (
                  <StandardItems
                    currency={currency}
                    updatingItem={updatingItem}
                    updateCartItemQuantity={updateCartItemQuantity}
                    removeItemFromCart={removeItemFromCart}
                    items={physicalItems.itemsWithoutFrequency}
                    productInfo={productInfo}
                    cartType={cartType}
                  />
                ) : (
                  <StandardItems
                    currency={currency}
                    updatingItem={updatingItem}
                    updateCartItemQuantity={updateCartItemQuantity}
                    removeItemFromCart={removeItemFromCart}
                    items={lineItems.physical_items}
                    productInfo={productInfo}
                    cartType={cartType}
                  />
                )}

                <StandardItems
                  currency={currency}
                  updatingItem={updatingItem}
                  updateCartItemQuantity={updateCartItemQuantity}
                  removeItemFromCart={removeItemFromCart}
                  items={lineItems.digital_items}
                  productInfo={productInfo}
                  cartType={cartType}
                />
                <CustomItems
                  currency={currency}
                  updatingItem={updatingItem}
                  updateCartItemQuantity={updateCartItemQuantity}
                  removeItemFromCart={removeItemFromCart}
                  items={lineItems.custom_items}
                  productInfo={productInfo}
                  cartType={cartType}
                />
                <GiftCertificateItems
                  currency={currency}
                  updatingItem={updatingItem}
                  removeItemFromCart={removeItemFromCart}
                  items={lineItems.gift_certificates}
                  productInfo={productInfo}
                  cartType={cartType}
                />
                {isMedication && (
                  <Flex mt={6} gap={6} alignItems={'center'}>
                    <Box flexShrink={0} className={styles.logo}>
                      <Icon symbol={'prescription'} />
                    </Box>
                    <Text
                      fontSize={14}
                      color={isMadPawsStore() ? 'var(--greyscale-dark-3x)' : '#7A8590'}
                      alignSelf={'flex-start'}
                    >
                      Products with this symbol require a vet prescription. Details are collected
                      post check out.
                    </Text>
                  </Flex>
                )}
              </div>
              {cartType === 'mini' && <CartSummary cartType={cartType} onClick={onClick} />}
            </div>
          </Box>
        </>
      )}
    </>
  );
};

export default Cart;

import React from 'react';
import clsx from 'clsx';
import * as pcStyles from '../Cart.module.scss';
import * as mpsStyles from '../Cart.mps.module.scss';
import { storeStyle } from '../../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const CartHeader = () => {
  return (
    <div className={styles.bcCartHeader}>
      <div className={clsx(styles.bcCartHeaderLabel, styles.bcCartHeaderItemCol)}>Item</div>
      <div className={styles.bcCartDetils}>
        {/* <div className={styles.bcCartHeaderLabel}>Price</div> */}
        <div className={`${styles.bcCartHeaderLabel} text-center`}>Qty</div>
        <div className={styles.bcCartHeaderLabel}>Total</div>
      </div>
    </div>
  );
};

export default CartHeader;

import { Center, Stack, Text, useToast } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import React, { useContext, useEffect, useMemo } from 'react';
import CartContext from '../../../context/CartProvider';
import Button from '../../atoms/Button/Button';
import NewProductCard from '../../atoms/NewProductCard/NewProductCard';
import * as styles from './FrequentProducts.module.scss';
import { isMadPawsStore } from '../../../common/util';

const FrequentProducts = ({ products }) => {
  const cartCtx = useContext(CartContext);
  const toast = useToast();
  const form = useForm({
    initialValues: {
      selectedProducts: [],
      loading: false
    }
  });

  const selectHandler = (productId, variantId, salePrice) => {
    const findIndex = form.values.selectedProducts.findIndex(
      item => item.productId === productId && item.variantId === variantId
    );

    if (findIndex >= 0) {
      form.removeListItem('selectedProducts', findIndex);
    } else {
      form.insertListItem('selectedProducts', {
        productId,
        variantId,
        salePrice
      });
    }
  };

  const addAllToCartHandler = async () => {
    try {
      form.setFieldValue('loading', true);
      const response = await cartCtx.addAllToCart(productsToAdd);

      if (response) {
        toast({
          title: 'Success',
          description: 'All items added to cart',
          status: 'success',
          position: 'top-right',
          colorScheme: 'primary'
        });
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: 'Something went wrong while adding items to the cart',
        status: 'error',
        position: 'top-right'
      });
    } finally {
      form.setFieldValue('loading', false);
    }
  };

  const totalPrice = useMemo(
    () =>
      form.values.selectedProducts
        ?.reduce((prev, product) => prev + product.salePrice, 0)
        ?.toFixed(2),
    [form.values.selectedProducts]
  );

  const productsToAdd = useMemo(
    () =>
      form.values.selectedProducts.map(item => ({
        product_id: item.productId,
        variant_id: item.variantId,
        quantity: 1
      })),
    [form.values.selectedProducts]
  );

  useEffect(() => {
    if (products) {
      form.setFieldValue(
        'selectedProducts',
        products.map(item => ({
          productId: item.entityId,
          variantId: item.variants?.[0]?.id,
          salePrice: item.calculated_price
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  return (
    <div className={styles.root}>
      {products?.map(node => (
        <NewProductCard
          landscape
          key={node.entityId}
          image={node.defaultImage?.url}
          productId={node.entityId}
          name={node.name}
          price={node.price}
          sku={node.sku}
          salePrice={node.calculated_price}
          rating={node.reviews_rating_sum}
          ratingCount={node.reviews_count}
          url={node.custom_url.url}
          selectable
          onSelect={selectHandler}
          variantId={node.variants?.[0]?.id}
          hasMoreOptions={node.variants?.length > 1}
          selected={form.values.selectedProducts.some(item => item.productId === node.entityId)}
        />
      ))}
      <Center className="add-to-cart" w={'full'} maxW={290} minH={200}>
        <Stack textAlign={'center'} spacing={6}>
          <Text
            fontSize={21}
            fontWeight={'bold'}
            color={isMadPawsStore() ? 'var(--greyscale-dark-4x)' : '-moz-initial'}
          >
            ${totalPrice}
          </Text>
          <Button
            level={'primary'}
            type={'span'}
            loading={form.values.loading}
            onClick={addAllToCartHandler}
            disabled={form.values.selectedProducts.length === 0 || form.values.loading}
            className={styles.addToCartss}
          >
            Add {form.values.selectedProducts.length} items to cart
          </Button>
        </Stack>
      </Center>
    </div>
  );
};

export default FrequentProducts;

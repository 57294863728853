// extracted by mini-css-extract-plugin
export var addressSearchResults = "confirm-order-module--addressSearchResults--54e8e";
export var addressSummary = "confirm-order-module--addressSummary--f9b3e";
export var bcCartEmpty = "confirm-order-module--bcCartEmpty--babdd";
export var bcCartTitle = "confirm-order-module--bcCartTitle--0959c";
export var bcConfirmOrderContainer = "confirm-order-module--bcConfirmOrderContainer--a8735";
export var bcConfirmOrderContainerEmpty = "confirm-order-module--bcConfirmOrderContainerEmpty--71e64";
export var billingSummary = "confirm-order-module--billingSummary--24598";
export var breadCrumbsContainer = "confirm-order-module--breadCrumbsContainer--745cc";
export var checkout = "confirm-order-module--checkout--d2a9b";
export var checkoutEmail = "confirm-order-module--checkoutEmail--1d940";
export var checkoutSteps = "confirm-order-module--checkoutSteps--03cf9";
export var completed = "confirm-order-module--completed--f508c";
export var continueButton = "confirm-order-module--continueButton--d973b";
export var continueShopping = "confirm-order-module--continueShopping--aed8b";
export var customerSummary = "confirm-order-module--customerSummary--4f9e4";
export var errorMessages = "confirm-order-module--errorMessages--4674b";
export var fieldError = "confirm-order-module--fieldError--fd3f0";
export var formFields = "confirm-order-module--formFields--a1f32";
export var full = "confirm-order-module--full--11847";
export var guestEmailButton = "confirm-order-module--guestEmailButton--ee126";
export var guestEmailOptions = "confirm-order-module--guestEmailOptions--85f26";
export var guestEmailWrapper = "confirm-order-module--guestEmailWrapper--da4f0";
export var loadingShipping = "confirm-order-module--loadingShipping--0a7af";
export var loginRequired = "confirm-order-module--loginRequired--7afc0";
export var manualAddressTrigger = "confirm-order-module--manualAddressTrigger--ae477";
export var orSeparate = "confirm-order-module--orSeparate--b0ce1";
export var recommendedAddOnsContainer = "confirm-order-module--recommendedAddOnsContainer--9a088";
export var recommendedAddOnsContainerMobile = "confirm-order-module--recommendedAddOnsContainerMobile--acf03";
export var root = "confirm-order-module--root--b077f";
export var setValue = "confirm-order-module--setValue--cbcb0";
export var shippingMethodSummary = "confirm-order-module--shippingMethodSummary--0f9d1";
export var shippingOptions = "confirm-order-module--shippingOptions--e1c36";
export var shippingSummary = "confirm-order-module--shippingSummary--5aa17";
export var spinnerWrapper = "confirm-order-module--spinnerWrapper--8788a";
export var step = "confirm-order-module--step--80a5a";
export var stepAction = "confirm-order-module--stepAction--ae2c6";
export var stepBody = "confirm-order-module--stepBody--c5210";
export var stepHeader = "confirm-order-module--stepHeader--d5922";
export var stepNumber = "confirm-order-module--stepNumber--5db16";
export var stepTitle = "confirm-order-module--stepTitle--77a27";
export var supportFunction = "confirm-order-module--supportFunction--1089f";
export var title = "confirm-order-module--title--38948";
import React from 'react';
// import { isEmpty } from 'lodash';
// import CartContext from '../../../context/CartProvider';
// import Button from '../../atoms/Button/Button';
// import { bcApi } from '../../../helpers/bigcommerce';

/* For use on confirm-order.js */
// import { get } from 'lodash';
// import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

import * as styles from './CartSummary.module.css';
import CurrencyFormatter from '../../atoms/CurrencyFormatter/CurrencyFormatter';

const ShippingInfo = ({checkout}) => {
  /* For use on confirm-order.js */
  // const selectedMethod = get(checkout, 'consignments[0].selected_shipping_option', {});

  // const [showForm, setShowForm] = useState(false);
  // const [countries, setCountries] = useState([]);
  // const [countryStates, setCountryStates] = useState([]);
  // const [selectedConsigment, setSelectedConsigment] = useState('');
  // const [formData, setFormData] = useState({});
  // const {
  //   state: {
  //     consignments,
  //     cart: { currency }
  //   },
  //   addConsignments,
  //   updateConsignments
  // } = useContext(CartContext);

  // const onChangeValue = e => {
  //   const { name, value } = e.target;
  //   if (name === 'email') {
  //   }
  //   let newFormData = { ...formData, [name]: value };
  //   if (name === 'country_code') {
  //     newFormData = { ...newFormData, state_or_province: '', city: '' };
  //   }
  //   setFormData(newFormData);
  // };

  // const onClickToggle = () => {
  //   setShowForm(!showForm);
  // };

  // const onSubmitForm = () => {
  //   addConsignments(formData);
  // };

  // const updateShoppingCost = () => {
  //   if (consignments[selectedConsigment]) {
  //     const { shipping_address, id: consignmentId } = consignments[
  //       selectedConsigment
  //     ];
  //     updateConsignments(shipping_address, consignmentId);
  //   }
  // };

  // const onChangeRadio = e => {
  //   setSelectedConsigment(+e.target.value);
  // };

  // useEffect(() => {
  //   setCountryStates([]);
  //   const countryObj = countries.find(
  //     c => c.country_iso2 === formData.country_code
  //   );
  //   if (countryObj) {
  //     const endpoint = countryObj.states.resource.substring(1);
  //     bcApi(`${endpoint}?limit=250`, 'GET', null, 2).then(response => {
  //       if (response.status === 200 && response.response) {
  //         setCountryStates(response.response);
  //       }
  //     });
  //   }
  // }, [countries, formData.country_code]);

  // useEffect(() => {
  //   bcApi(`countries?limit=250`, 'GET', null, 2).then(response => {
  //     if (response.status === 200) {
  //       setCountries(response.response);
  //     }
  //   });
  // }, []);

  return (
    <>
      <div className="flex-between">
        <span className={styles.bcCartSummaryItemLabel}>Shipping:</span>
        <span className={styles.bcCartSummaryItemValue}>
        <CurrencyFormatter amount={checkout?.shipping_cost_total_inc_tax} currency='AUD' appendZero={true} />
        </span>

        {/* For use on confirm-order.js */}
        {/* <span className={styles.bcCartSummaryItemValue}>
          {'cost' in selectedMethod ? (
            <CurrencyFormatter amount={selectedMethod.cost} currency='AUD' appendZero={true} />
          ) : '-'}
        </span> */}

        {/* <span
          data-button
          role="presentation"
          onClick={onClickToggle}
          className={styles.bcCartSummaryToggle}
        >
          {showForm ? 'Cancel' : 'Add Info'}
        </span> */}
      </div>
      {/* {showForm && (
        <div className="pt-4">
          <div className="grid-gap20 grid-3070 formField">
            <label htmlFor="country" className="middle">
              Country
            </label>
            {!isEmpty(countries) && (
              <select
                value={formData.country_code}
                name="country_code"
                onBlur={onChangeValue}
                onChange={onChangeValue}
              >
                <option>Select a country</option>
                {countries.map(country => (
                  <option key={country.id} value={country.country_iso2}>
                    {country.country}
                  </option>
                ))}
              </select>
            )}
          </div>
          <div className="grid-gap20 grid-3070 formField">
            <label htmlFor="state_or_province" className="middle">
              State/Province
            </label>
            {!isEmpty(countryStates) && (
              <select
                value={formData.state_or_province}
                name="state_or_province"
                onBlur={onChangeValue}
                onChange={onChangeValue}
              >
                <option>Select a state</option>
                {countryStates.map((state, stateIndex) => (
                  <option key={stateIndex} value={state.state}>
                    {state.state}
                  </option>
                ))}
              </select>
            )}
            {isEmpty(countryStates) && (
              <input
                type="text"
                value={formData.state_or_province || ''}
                name="state_or_province"
                onChange={onChangeValue}
              />
            )}
          </div>
          <div className="grid-gap20 grid-3070 formField">
            <label htmlFor="city" className="middle">
              Suburb/city
            </label>
            <input
              type="text"
              name="city"
              value={formData.city || ''}
              onChange={onChangeValue}
            />
          </div>
          <div className="grid-gap20 grid-3070 formField">
            <label htmlFor="postal_code" className="middle">
              Zip/postcode
            </label>
            <input
              type="text"
              name="postal_code"
              value={formData.postal_code || ''}
              onChange={onChangeValue}
            />
          </div>
          <div className="grid-gap20 grid-3070">
            <div />
            <Button
              type="button"
              level="primary"
              size="smallest"
              onClick={onSubmitForm}
            >
              Estimated Shipping
            </Button>
          </div>
        </div>
      )}
      {!isEmpty(consignments) && showForm && (
        <div className="pt-4">
          {consignments.map((consi, index) => (
            <div key={consi.id} className="grid-gap20 grid-7030 formField">
              <div className="flex-center">
                <input
                  id={consi.id}
                  name="shipping_address"
                  value={index}
                  type="radio"
                  checked={selectedConsigment === index}
                  onChange={onChangeRadio}
                />
                <label htmlFor={consi.id} className="ml-4">
                  {consi.shipping_cost_ex_tax > 0
                    ? 'Flat Rate'
                    : 'Free Shipping'}
                </label>
              </div>
              <div className="flex-end">
                <CurrencyFormatter
                  currency={currency.code}
                  amount={consi.shipping_cost_ex_tax}
                  ignoreTax={true}
                />
              </div>
            </div>
          ))}
          <div className="grid-gap20 grid-3070">
            <div />
            <Button
              type="button"
              level="primary"
              size="smallest"
              onClick={updateShoppingCost}
            >
              Update shipping cost
            </Button>
          </div>
        </div>
      )} */}
    </>
  );
};

export default ShippingInfo;

import { Box, Flex, Image, Select, Text, VStack, useToast } from '@chakra-ui/react';
import { useForm } from '@mantine/form';
import LoadingOverlay from 'components/atoms/LoadingOverlay/LoadingOverlay';
import React, { useContext, useEffect } from 'react';
import CartContext from '../../../context/CartProvider';
import { useGlobalStore } from '../../../stores/global';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import Icon from '../../atoms/Icon/Icon';
import * as pcStyles from './AutoShip.module.scss';
import * as mpsStyles from './AutoShip.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../../../common/util';
import AutoShipToolTip from './AutoShipToolTip';

const styles = storeStyle({ mpsStyles, pcStyles });
const AutoShip = ({ step }) => {
  const {
    frequency,
    setFrequency,
    frequencySelected,
    setFrequencySelected,
    toggleAutoship: isOpen,
    setToggleAutoship
  } = useGlobalStore(state => state);
  // const autoship = useGlobalStore(state => state);

  const toast = useToast();
  const form = useForm({
    initialValues: {
      frequency: '',
      activeProduct: []
    }
  });
  const ctx = useContext(CartContext);
  const cart = ctx?.state?.cart;

  const onToggle = () => {
    setToggleAutoship(!isOpen);
  };
  const processAutoshipHandler = (value, skip = false) => {
    const { activeProduct } = form.values;
    if (!skip) setFrequency(true);
    let action = activeProduct.includes(value) && !skip ? 'remove' : 'add';
    if (!skip) {
      form.setFieldValue(
        'activeProduct',
        action === 'remove'
          ? activeProduct.filter(item => item !== value)
          : [...activeProduct, value]
      );
    }

    const findCartLineItem = cart.lineItems.physical_items.find(item => String(item.id) === value);
    const findActiveModifier = findCartLineItem?.options?.find(item => item.name === 'Ships Every');
    if (action === 'add' && findActiveModifier && findActiveModifier?.value === frequencySelected) {
      setFrequency(false);
      return undefined;
    }
    // check if product exists variants
    const optionSelections = [];
    const productSelected = cart?.autoShipProducts.find(item => item.id === value);

    const findVariant = productSelected?.details?.variants?.find(
      item => item.id === findCartLineItem?.variant_id
    );

    if (findVariant?.option_values?.[0]) {
      optionSelections.push({
        option_id: findVariant.option_values[0].option_id,
        option_value: findVariant.option_values[0].id
      });
    }
    const productModifiers = productSelected?.details?.modifiers;
    // check if product have ship frequency selected
    const autoShipModifier = productModifiers?.find(item => item.name === 'Ships Every');
    const autoShipOption = autoShipModifier?.option_values?.find(
      item => item.label === frequencySelected
    );

    if (autoShipOption) {
      optionSelections.push({
        option_id: autoShipOption.option_id,
        option_value: autoShipOption.id
      });
    }

    const petNameModifier = productModifiers?.find(modifier =>
      modifier.name.includes("Pet's Name")
    );
    if (petNameModifier) {
      const petNameOption = productSelected.options.find(o => o.nameId === petNameModifier.id);
      if (petNameOption) {
        optionSelections.push({
          option_id: petNameModifier.id,
          option_value: petNameOption.value
        });
      }
    }
    let params = {
      line_items: [
        {
          product_id: findCartLineItem?.product_id,
          quantity: findCartLineItem?.quantity,
          option_selections: optionSelections
        }
      ]
    };
    if (action === 'remove') {
      delete params.line_items[0].option_selections;
      params.line_items[0].variant_id = findCartLineItem?.variant_id;
    }

    if (!skip) setFrequency(false);

    return params;
  };

  const checkboxHandler = async value => {
    //check if product is in activeProduct. If not , check if frequency is set. If not, show toast
    if (!form.values.activeProduct.includes(value) && !frequencySelected) {
      if (!toast.isActive('frequency'))
        toast({
          id: 'frequency',
          title: 'Frequency is required',
          description: 'Please select autoship frequency',
          status: 'error',
          position: 'top-right'
        });
      return;
    }
    //process autoship item data
    const newLineItem = processAutoshipHandler(value);
    try {
      const upsert = await ctx.replaceCartItem(newLineItem, [value]);
      form.setFieldValue('activeProduct', upsert ?? []);
    } catch (error) {}
  };

  useEffect(() => {
    if (cart.lineItems.physical_items.length > 0) {
      let frequencyData;
      cart.lineItems.physical_items.map(item => {
        const autoShipData = item.options.find(option => option.name === 'Ships Every');
        if (autoShipData) frequencyData = autoShipData;
      });
      if (frequencyData) {
        setFrequencySelected(frequencyData.value);
      } else {
        setToggleAutoship(false);
        setFrequencySelected('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart]);
  useEffect(() => {
    const setAutoShip = async () => {
      // Remove autoship for all products
      if (!isOpen || !!frequencySelected) {
        const newLineItems = form.values.activeProduct.map(item =>
          processAutoshipHandler(item, isOpen && !!frequencySelected)
        );

        if (newLineItems.filter(item => item !== undefined).length === 0) return;

        const merged = {
          line_items: newLineItems.map(obj => obj.line_items).flat()
        };

        const upsert = await ctx.replaceCartItem(merged, form.values.activeProduct);

        form.setFieldValue('activeProduct', upsert ?? []);
      }
    };
    if (form.values.activeProduct.length) setAutoShip();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [frequencySelected, isOpen]);

  useEffect(() => {
    const pageInit = async () => {
      if (cart?.lineItems.physical_items?.length > 0) {
        let selectedFrequency = '';
        const autoshipProducts = cart?.lineItems.physical_items
          ?.map(item => {
            const findAutoshipOption = item.options.find(option => option.name === 'Ships Every');

            if (findAutoshipOption) {
              if (!selectedFrequency) selectedFrequency = findAutoshipOption.value;
              return item.id;
            }
            return null;
          })
          .filter(Boolean);
        if (autoshipProducts.length > 0) {
          setToggleAutoship(true);
          form.setValues({
            activeProduct: autoshipProducts,
            frequency: selectedFrequency
          });
        }
      }
    };
    pageInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cart?.lineItems?.physical_items, step]);

  // const { data: autoShipProducts, isLoading: fetchingProducts } = useQuery(
  //   ['auto-ship-products'],
  //   async () =>
  //     bcApi(
  //       `catalog/products?is_visible=true&include=variants,options,images,modifiers&id:in=${[
  //         '10132',
  //       ].join(',')}`,
  //       'GET'
  //     )
  // );

  return (
    <section className={styles.root}>
      <div onClick={onToggle} className="top-section">
        <Checkbox
          className={'select'}
          variant={'outline'}
          isChecked={isOpen}
          action={onToggle}
          label={
            <>
              <Flex align={'center'} gap={1} className="info" wrap={'wrap'}>
                <Text
                  fontWeight={'bold'}
                  color={isMadPawsStore() ? 'var(--primary-default)' : '#0D7AA0'}
                  fontSize={'18px'}
                >
                  Add
                </Text>
                <Icon symbol={'auto-ship'} />
                <Text
                  fontWeight={'bold'}
                  color={isMadPawsStore() ? 'var(--primary-default)' : '#0D7AA0'}
                  fontSize={'18px'}
                >
                  and you could save up to 20%*
                </Text>
              </Flex>
              <VStack align={'baseline'} spacing={4}>
                <Text
                  color={isMadPawsStore() ? 'var(--greyscale-dark-3x)' : '#5D6975'}
                  fontSize={14}
                >
                  Subscribe for convenient recurring deliveries. Cancel, edit or skip any time.
                </Text>
                <AutoShipToolTip />
                <Text
                  color={isMadPawsStore() ? 'var(--greyscale-dark-2x)' : '#5D6975'}
                  fontSize={12}
                >
                  *Maximum AutoShip discount, subsequent order discount may vary, see the product
                  page for more info. No discount on prescription medication.
                </Text>
              </VStack>
            </>
          }
        />
      </div>

      {isOpen && (
        <Box className={'frequency'}>
          <Text className="title" mb={3}>
            delivery frequency
          </Text>
          <Select
            maxW={'248px'}
            rounded={'none'}
            value={frequencySelected}
            onChange={e => {
              setFrequencySelected(e.currentTarget.value);
            }}
          >
            <option value="">Select Frequency</option>
            {cart?.frequencyOptions?.map(option => (
              <option key={option.id} value={option.label}>
                {option.label}
              </option>
            ))}
          </Select>

          <Text mt={3} mb={6} color={isMadPawsStore() ? 'var(--greyscale-dark-3x)' : '#5D6975'}>
            You can change , cancel or reschedule shipments at any time.
          </Text>
          <Text className="title" mb={4}>
            APPLY AUTOSHIP TO{' '}
          </Text>
          <Flex gap={4} pos={'relative'} flexWrap={'wrap'}>
            {frequency && <LoadingOverlay position="absolute" />}
            {cart?.autoShipProducts
              ?.sort(
                (a, b) =>
                  form.values.activeProduct.includes(b.id) -
                  form.values.activeProduct.includes(a.id)
              )
              .map(product => (
                <Box
                  h={20}
                  w={20}
                  key={product.id}
                  pos={'relative'}
                  role="button"
                  onClick={() => checkboxHandler(product.id)}
                >
                  <div
                    className={`checkbox ${
                      form.values.activeProduct.includes(product.id) ? 'active' : ''
                    }`}
                  ></div>
                  <Image
                    w="full"
                    h={'full'}
                    src={product.image_url ?? '/404.svg'}
                    alt={product.name}
                    objectFit={'contain'}
                    m={0}
                  />
                </Box>
              ))}
          </Flex>
        </Box>
      )}
    </section>
  );
};

export default AutoShip;

import { Box, Center, Flex, Spinner, Text } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { get } from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import CartContext from '../../../context/CartProvider';
import { bcApi } from '../../../helpers/bigcommerce';
import Checkbox from '../../atoms/Checkbox/Checkbox';
import { useCartStore } from 'stores/cart';
import LoadingOverlay from 'components/atoms/LoadingOverlay/LoadingOverlay';
import * as pcStyles from './CustomService.module.scss';
import * as mpsStyles from './CustomService.mps.module.scss';
import { storeStyle, isMadPawsStore } from '../../../common/util';

const styles = storeStyle({ mpsStyles, pcStyles });
const CustomService = () => {
  const [ins, setIns] = useState();
  const [reply, setReply] = useState();
  const [signature, setSignatire] = useState();

  const { service, setService } = useCartStore(state => state);
  const ctx = useContext(CartContext);
  const cart = get(ctx, 'state.cart', {});
  const { lineItems, productInfo } = cart;

  const isMedication = useMemo(() => {
    if (lineItems && productInfo) {
      return lineItems?.physical_items?.some(item =>
        productInfo[item.product_id]?.custom_fields?.some(
          field => field.name === 'Product Type' && field.value === 'Medication'
        )
      );
    }
    return false;
  }, [lineItems, productInfo]);

  const { data: serviceProducts, isLoading: fetchingServiceProducts } = useQuery(
    ['service_products'],
    async () =>
      bcApi(
        `catalog/products?include=variants,options,images,custom_fields&sku:in=${[
          'INS',
          'REPLY',
          'SIGREQ'
        ].join(',')}`,
        'GET'
      )
  );

  const actionHandler = useCallback(
    sku => {
      let processProduct;

      setService(true);
      if (sku === 'REPLY') {
        processProduct = reply;
      } else if (sku === 'INS') {
        processProduct = ins;
      } else {
        processProduct = signature;
      }
      // If processProduct exists, we remove it from the cart.
      if (processProduct) {
        ctx.removeItemFromCart(processProduct.id);
      } else {
        // Only look for the product if we didn't already find processProduct.
        const findProduct = serviceProducts?.response?.data?.find(product => product.sku === sku);

        // If findProduct exists, we add it to the cart.
        if (findProduct) {
          ctx.addToCart({
            productId: findProduct.variants?.[0]?.product_id,
            variantId: findProduct.variants?.[0]?.id
          });
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [serviceProducts, ins, reply, ctx, signature]
  );

  useEffect(() => {
    const pageInit = () => {
      const findIns = cart.lineItems.physical_items.find(item => item.sku === 'INS');
      const findReply = cart.lineItems.physical_items.find(item => item.sku === 'REPLY');
      const findSig = cart.lineItems.physical_items.find(item => item.sku === 'SIGREQ');

      setIns(findIns);
      setReply(findReply);
      setSignatire(findSig);
    };

    cart?.lineItems?.physical_items && pageInit();
  }, [cart]);

  const insProduct = useMemo(() => {
    return serviceProducts?.response?.data?.find(product => product.sku === 'INS');
  }, [serviceProducts]);

  const replyProduct = useMemo(() => {
    return serviceProducts?.response?.data?.find(product => product.sku === 'REPLY');
  }, [serviceProducts]);

  const sigProduct = useMemo(() => {
    return serviceProducts?.response?.data?.find(product => product.sku === 'SIGREQ');
  }, [serviceProducts]);

  const textColor = isMadPawsStore() ? 'var(--greyscale-dark-4x)' : '';
  return (
    <>
      {fetchingServiceProducts ? (
        <Center py={6}>
          <Spinner size={'lg'} color="teal" />
        </Center>
      ) : (
        <Box className={styles.root} pos={'relative'}>
          {service && <LoadingOverlay position="absolute" />}
          <Flex justify={'space-between'} py={3} className={`container priority`}>
            <Checkbox
              className={'select'}
              variant={'outline'}
              name={'ins'}
              id={'ins'}
              isChecked={!!ins}
              label={
                <>
                  <Text color={textColor}>Add Priority Processing Service </Text>
                  <Text color={isMadPawsStore() ? 'var(--greyscale-dark-3x)' : ''} fontSize={14}>
                    for urgent orders &nbsp;
                    <Text
                      as={'a'}
                      color={isMadPawsStore() ? 'var(--primary-default)' : '#006E95'}
                      target="_blank"
                      href={insProduct?.custom_url?.url}
                      className="underline"
                    >
                      Learn more
                    </Text>{' '}
                  </Text>
                </>
              }
              action={() => actionHandler('INS')}
            />
            <Text color={textColor}>${insProduct?.calculated_price}</Text>
          </Flex>

          {isMedication && (
            <>
              <Flex justify={'space-between'} py={3} className={`container `}>
                <Checkbox
                  className={`select ${styles.capitalize}`}
                  variant={'outline'}
                  name={'reply'}
                  id={'reply'}
                  isChecked={!!reply}
                  label={<Text color={textColor}>Add reply paid envelope</Text>}
                  action={() => actionHandler('REPLY')}
                />
                <Text color={textColor}>${replyProduct?.calculated_price}</Text>
              </Flex>

              <Flex justify={'space-between'} py={3} className={`container `}>
                <Checkbox
                  className={`select`}
                  variant={'outline'}
                  name={'signature'}
                  id={'signature'}
                  isChecked={!!signature}
                  label={<Text color={textColor}>Signature required on delivery </Text>}
                  action={() => actionHandler('SIGREQ')}
                />
                <Text color={textColor}>${sigProduct?.calculated_price}</Text>
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  );
};

export default CustomService;

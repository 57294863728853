import React, { useContext, useEffect, useState } from 'react'
import Icon from '../../atoms/Icon/Icon';
import CartContext from '../../../context/CartProvider';
import { get } from 'lodash';
// import { getStorage, setStorage } from '../../../helpers/general';
// import { LOCAL_STORE } from '../../molecules/Localisr/Statics';
import Localisr from '../../molecules/Localisr/Localisr';
import Loader from '../../atoms/Loader/Loader';

import * as styles from './CartShippingOptions.module.css'

const CartShippingOptions = ({ isValid, setShippingAddress, setShippingOptions }) => {

    const DELIVERY = 'delivery';
    const COLLECT = 'collect';

    const ctx = useContext(CartContext);
    const initCheckout = get(ctx, 'initCheckout');
    const changeShippingMethod = get(ctx, 'changeShippingMethod');
    const cartPhysicalItems = get(ctx, 'state.cart.lineItems.physical_items');
    const ctxSelectedStore = get(ctx, 'state.selectedStore');
    const ctxStockLoading = get(ctx, 'state.stockLoading');
    const ctxStockAvailability = get(ctx, 'state.stockAvailability');
    const [localStockAvailability, setLocalStockAvailability] = useState(ctxStockAvailability);
    
    const ctxShippingMethod = get(ctx, 'state.shippingMethod');
    const [localShippingMethod, setLocalShippingMethod] = useState(ctxShippingMethod);

    const [selected, setSelected] = useState(ctxShippingMethod || '');
    const defaultStore = {
        store_id: ctxSelectedStore.store_id || '',
        store_name: ctxSelectedStore.store_name || '',
        store_address: ctxSelectedStore.store_address || '',
        store_location: ctxSelectedStore.store_location || '',
    };
    const [selectedStore, setSelectedStore] = useState({...defaultStore});
    const [loading, setLoading] = useState(false);
    const [forcingMethodUpdate, setForcingMethodUpdate] = useState(false);
    const [issueMessage, setIssueMessage] = useState(null);

    useEffect(() => {
        if (ctxSelectedStore && ctxSelectedStore.store_id && setSelectedStore) {
            setSelectedStore(ctxSelectedStore);
        }
    }, [ctxSelectedStore, setSelectedStore]);

    const selectOption = async (shippingType, force) => {
        if (selected !== shippingType || force) {
            isValid(false);
            setLoading(true);
            const result = await changeShippingMethod(shippingType);
            if (result === 'forceDelivery') {
                setSelected(DELIVERY);
                validTest(DELIVERY);
                setIssueMessage("The current item(s) in the cart can not be collected.");
            } else {
                setSelected(shippingType);
                validTest(shippingType);
            }

            setLoading(false);

            if (!force) {
                setForcingMethodUpdate(false);
            }
        }
    }

    const onSelectStore = (newStore) => {
        changeShippingMethod(COLLECT, {...ctx.state, selectedStore: newStore});
        validTest(selected, newStore);
    }

    const validTest = (method = '', store = false) => {
        const _method = method || selected;
        const _store = store || selectedStore;
        if (_method === COLLECT && _store.store_id !== '' && ctxStockAvailability.length === 0) {
            isValid(false);
        } else if (_method === COLLECT && _store.store_id !== '') {
            isValid(true);
        } else if (_method === COLLECT && _store.store_id === '') {
            isValid(false);
        } else if (_method === DELIVERY) {
            if (_store.store_id !== '') {
                // Update cart if set to
                setShippingAddress && setShippingAddress({billingSameAsShipping: true});
                setShippingOptions && setShippingOptions([]);
            }
            isValid(true);
        }
    }

    useEffect(() => {
        validTest();
        const initProcess = async () => {
            if (selected === COLLECT && selectedStore.store_id !== '') {
                setLoading(true);
                const result = await changeShippingMethod(selected);
                if (result === 'forceDelivery') {
                    setSelected(DELIVERY);
                    validTest(DELIVERY);
                    setIssueMessage("The current item(s) in the cart can not be collected.");
                }

                initCheckout();
                setLoading(false);
            }
        }
        initProcess();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (JSON.stringify(ctxStockAvailability) !== JSON.stringify(localStockAvailability)) {
            setLocalStockAvailability(ctxStockAvailability);
            validTest();
        }
        // eslint-disable-next-line
    }, [ctxStockAvailability])

    useEffect(() => {
        if (!forcingMethodUpdate) {
            setLocalShippingMethod(ctxShippingMethod);
        }
        // eslint-disable-next-line
    }, [ctxShippingMethod])

    useEffect(() => {
        if (selected !== localShippingMethod && !loading) {
            // Force the right selected shipping method. `selected` maintains the user selection and should be followed.
            setForcingMethodUpdate(true);
            setLocalShippingMethod(selected);
            selectOption(selected, true);
        }
        // eslint-disable-next-line
    }, [localShippingMethod, selected, loading]);

    return (
        <div className={styles.optionsContainer}>
            {issueMessage && (
                <div className={styles.issueMessage}>
                    <Icon symbol="alert" /> {issueMessage}
                </div>
            )}
            <div className={styles.options}>
                <div role="presentation" className={`${styles.option} ${localShippingMethod === DELIVERY ? styles.selected : ''}`} onClick={() => selectOption(DELIVERY)}>
                    <div className={styles.optionTitle}>
                        <span className={styles.title}><Icon symbol='truck' /> Delivery</span>
                        <span className={styles.radio}></span>
                    </div>
                    <div className={styles.optionDesc}>
                        Shipping calculated at checkout
                    </div>
                </div>

                <div role="presentation" className={`${styles.option} ${localShippingMethod === COLLECT ? styles.selected : ''}`} onClick={() => selectOption(COLLECT)}>
                    <div className={styles.optionTitle}>
                        <span className={styles.title}><Icon symbol='house' />Collect from store</span>
                        <span className={styles.radio}></span>
                    </div>
                    <div className={styles.optionDesc}>
                        <div className={styles.ccStoreWrap}>
                            { (localShippingMethod !== COLLECT || (localShippingMethod === COLLECT && selectedStore.store_id === '')) && (<span>Order online, collect in-store</span>) }
                            { localShippingMethod === COLLECT && selectedStore.store_id !== '' && (<span className={styles.ccStoreDetail}>
                                <span className={styles.ccStoreName}>{selectedStore.store_name}</span>
                                <span className={styles.ccStoreAddress}>{selectedStore.store_address}</span>
                            </span>) }
                            <span className={[styles.ccChangeStore, ...(localShippingMethod === COLLECT ? [] : ['hide-option'])].join(' ')}>
                                <Localisr text={selectedStore.store_id === '' ? 'Select a Store' : 'Change Store'} products={cartPhysicalItems} onStoreSelected={onSelectStore}/>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            {(loading || ctxStockLoading) && (
                <div className={styles.loadingSwitching}>
                    <Loader />
                </div>
            )}
        </div>
    )
}

export default CartShippingOptions;